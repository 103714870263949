import Link from "next/link";

const ErrorPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <span className="text-gray-900">
        Oops! it seems you have encountered an error.
      </span>
      <Link href="/">
        <a className="text-gray-600 hover:text-gray-900">Back to Safety</a>
      </Link>
    </div>
  );
};

export default ErrorPage;
